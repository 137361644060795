import * as React from "react";
// import { graphql } from "gatsby";
// import { GatsbyImage } from "gatsby-plugin-image";
import { Page } from "~/components/ui";

const AtliktiDarbai = ({ data }) => {

  const seo = {
    title: "Atlikti darbai",
  }

  return (
    <Page {...seo}>
      <div className="prose mb-12">
        <h1>Atlikti darbai</h1>


      </div>
    </Page>
  )
}

// {data.posts.edges.map(({ node }) => (
//   <div key={node.id} className="border-t mb-8">
//     <p>
//       {node.caption.replace(/#.*/, "")}
//     </p>
//     <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//       {node.images.map((image) => (
//         <div key={image.id} className="aspect-1">
//           <GatsbyImage
//             image={image.localFile.childImageSharp.gatsbyImageData}
//             className="w-full h-full origin-center object-cover lg:w-full lg:h-full"
//             alt={node.caption}
//           />
//         </div>
//       ))}
//     </div>
//   </div>
// ))}

export default AtliktiDarbai;

// export const query = graphql`
//   query {
//     posts: allInstaNode(
//       filter: {caption: {regex: "/#project/"}}
//       sort: {fields: timestamp, order: DESC}
//     ) {
//       edges {
//         node {
//           id
//           caption
//           images: carouselImages {
//             id
//             localFile {
//               childImageSharp {
//                 gatsbyImageData
//               }
//             }
//           }
//         }
//       }
//     }
//     instagram: instaNode {
//       username
//     }
//   }
// `;